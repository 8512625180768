
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { useContext, useState } from 'react';
import { Context } from 'Context';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller } from 'swiper/modules';


export const OurCampuses = (props: any) => {
  const appContext = useContext(Context)
  const campusesData = appContext.data.sections.find(section => section.name === "kampuslerimiz");
  const firstItem = campusesData?.children[0]
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const [controlledSwiper2, setControlledSwiper2] = useState(null);


  const CampusesMobile = () => {
    return <div className="our-campuses__mobile">
      <Swiper
        threshold={0}
        touchReleaseOnEdges
        className='our-campuses__images'
        spaceBetween={16}
        autoHeight={false}
        grabCursor
        slidesPerView={1}
        slidesOffsetAfter={0}
        controller={{ control: controlledSwiper }}
        onSwiper={setControlledSwiper2}
        modules={[Controller]}
        loop={false}
      >
        {campusesData?.children.map((campusItem, idx) => {
          return <SwiperSlide key={idx} >
            <div
              style={{ backgroundImage: `url(_assets/img/${campusItem.image})` }}
              className="our-campuses__right-img"></div>
          </SwiperSlide>
        })}
      </Swiper>

      <Swiper
        threshold={0}
        touchReleaseOnEdges
        className='our-campuses__swiper'
        spaceBetween={0}
        autoHeight={false}
        grabCursor
        slidesPerView={1.3}
        slideToClickedSlide
        controller={{ control: controlledSwiper2 }}
        onSwiper={setControlledSwiper}
        modules={[Controller]}
      >
        {campusesData?.children.map((campusItem, idx) => {
          return <SwiperSlide key={idx} >
            <span className="title">{campusItem.title}</span>
            <span className="address">{campusItem.content}</span>
            <a href={campusItem.link} target="_blank" className="location">{appContext.data.commonWords.find(item => item.key === 'location')!.label}</a>
          </SwiperSlide>
        })}
      </Swiper>


    </div>
  }

  const CampusesDesktop = () => {
    return <div className="d-flex align-content-center flex-wrap our-campuses-center">

      <Tab.Container id="left-tabs-example" defaultActiveKey={firstItem?.name}>
        <Col className=" col-12 col-md-6 our-campuses__left">
          <Nav className="nav vertical-tabs" >
            {campusesData?.children.map((campusItem, idx) => {
              return <Nav.Link key={idx} eventKey={campusItem.name}>
                <span className="title">{campusItem.title}</span>
                <span className="address">{campusItem.content}</span>
                <a href={campusItem.link} target="_blank" className="location" onClick={() => window.open(campusItem.link)}>
                  {appContext.data.commonWords.find(item => item.key === 'location')!.label}
                </a>
              </Nav.Link>


            })}
          </Nav>
        </Col>

        <Col className="col-12 col-md-6 mb-3 mb-md-0 our-campuses__right">
          <Tab.Content>
            {campusesData?.children.map((campusItem, idx) => {
              return <Tab.Pane eventKey={campusItem.name} key={idx}>
                <div style={{ backgroundImage: `url(_assets/img/${campusItem.image})`, backgroundPosition: campusItem.name === "kurtkoy" ? "left" : "top" }} className="our-campuses__right-img"></div>
              </Tab.Pane>
            })}
          </Tab.Content>
        </Col>
      </Tab.Container>
    </div>
  }


  return (
    <div className="our-campuses section header-white kampuslerimiz" data-id="kampuslerimiz">
      <div className="box">
        <h1 className="section-title white">{campusesData?.title}</h1>
        <div className="mt-0">
          {CampusesDesktop()}
          {CampusesMobile()}
        </div>
      </div>
    </div>
  )
}