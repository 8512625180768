
import { useContext, useEffect, useRef } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { HomeSlider } from './HomeSlider';
import { TechnologyTab } from './TechnologyTab';
import { OurServices } from './OurServices';
import { OurInnovations } from './OurInnovations';
import { OurProjectSolution } from './OurProjectSolution';
import { SuccessTogether } from './SuccessTogether';
import { OpenPositions } from './OpenPositions';
import { OurAwards } from './OurAwards';
import { OurCampuses } from './OurCampuses';
import { Footer } from 'Components/Footer';
import { Context } from 'Context';
import { useSearchParams } from 'react-router-dom';
import { SECTION_NAMES } from '../../data';


const HomePage = () => {

  const appContext = useContext(Context);
  const fullpageRef = useRef<any>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedSection = searchParams.get("section") || ""
  const selectedSubSection = searchParams.get("subsection") || "";


  useEffect(() => {
    return () => {
      appContext.setMenuTransparent?.(false)
    }
  }, [])


  useEffect(() => {
    if (fullpageRef) {
      fullpageRef.current.fullpageApi.moveTo(selectedSection)
    }
  }, [selectedSection])


  return (
    <ReactFullpage
      licenseKey='9GL08-9K64I-KK7S6-TJRQI-RJLGN'
      paddingTop='110px'
      menu=".header"
      ref={fullpageRef}
      verticalCentered
      //normalScrollElements='.technology-tab .tab-wrap #v-pills-tabContent .tab__content p, .our-innovations .swiper-slide-info_text-description'
      credits={{ enabled: false }}
      scrollingSpeed={450}
      responsiveWidth={991}
      scrollOverflow={false}
      anchors={[SECTION_NAMES.Main, SECTION_NAMES.Technology, SECTION_NAMES.OurServices, SECTION_NAMES.OurInnovations,
      SECTION_NAMES.OurProjectSolution, SECTION_NAMES.SuccesTogether, SECTION_NAMES.OpenPositions, SECTION_NAMES.OurAwards, SECTION_NAMES.OurCampuses, SECTION_NAMES.Footer]}
      animateAnchor
      //lockAnchors
      afterLoad={(origin, destination, direction, trigger) => {
        if ((origin.anchor === destination.anchor && origin.index === 0) || destination.index === 0) {
          appContext.setMenuTransparent?.(true);
        }
        else {
          appContext.setMenuTransparent?.(false)
        }
        const navigationElement = document.getElementById('fp-nav');
        if (navigationElement)
          navigationElement.style.display = destination.index === 0 ? 'none' : 'block'
      }}
      beforeLeave={(origin, destination, direction, trigger) => {
        if ((origin.anchor === destination.anchor && origin.index === 0) || destination.index === 0) {
          appContext.setMenuTransparent?.(true);
        }
        else {
          appContext.setMenuTransparent?.(false)
        }
        const navigationElement = document.getElementById('fp-nav');
        if (navigationElement) {
          navigationElement.style.display = destination.index === 0 ? 'none' : 'block'
          navigationElement.className = (destination.index !== 1 && destination.index !== 3 && destination.index !== 7) ? 'fp-left gray-background' : 'fp-left'
        }
      }}
      navigation={window.innerWidth > 991 ? true : false}
      navigationPosition='left'
      render={({ state, fullpageApi }) => {

        return (
          <ReactFullpage.Wrapper >
            <HomeSlider fullpageState={state} />
            <TechnologyTab selectedSubSection={selectedSubSection} />
            <OurServices selectedSubSection={selectedSubSection} />
            <OurInnovations selectedSubSection={selectedSubSection} />
            <OurProjectSolution selectedSubSection={selectedSubSection} />
            <SuccessTogether selectedSubSection={selectedSubSection} />
            <OpenPositions selectedSubSection={selectedSubSection} />
            <OurAwards selectedSubSection={selectedSubSection} />
            <OurCampuses selectedSubSection={selectedSubSection} />
            <Footer selectedSubSection={selectedSubSection} />
          </ReactFullpage.Wrapper>
        )
      }}
    />
  );
}

export default HomePage;
